import { httpClient } from '@/api/http';
import { Answer } from '../types';
import { slowBrainAnswers } from './mocks';

export const getSlowBrainAnswers = async (): Promise<Answer[]> => {
	const { data } = await httpClient.get<Answer[]>('/sessions/1/flows/slow_brain/answers');

	return data;
};

export const mockGetSlowBrainAnswers = async (): Promise<Answer[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(slowBrainAnswers), 500);
	});
