import { SlowBrainTileSelectionStep, StepDescriptionDialogContent } from '../../../types';

export const STEP_DESCRIPTION_DIALOG_CONTENT_MAP: Record<
	SlowBrainTileSelectionStep,
	StepDescriptionDialogContent
> = {
	personal_outcomes: {
		serialNumber: 1,
		title: 'Personal Outcomes',
		subtitles: ["To arrive at these outcomes, we'd like you to ask yourself the question:"],
		info: '"If you were to move well into the future and look back on your life, what are the Personal Outcomes you would want to know came true?"',
	},
	money_outcomes: {
		serialNumber: 2,
		title: 'Money Outcomes',
		subtitles: [
			'The second part of the "Slow Brain" section is your Money Outcomes. To arrive at these outcomes, we\'d like you to ask yourself the question:',
		],
		info: '"What do I want my money to do for me?"',
	},
	plan_vulnerabilities: {
		serialNumber: 3,
		title: 'Plan Vulnerabilities',
		subtitles: [
			'The final section of "Slow Brain" is your Plan Vulnerabilities. To discover these, we\'d like you to ask yourself the question:',
		],
		info: '"Where do I feel a lack of certainty or control in my life plan?"',
	},
	top_5_selection: {
		serialNumber: 4,
		title: 'Top 5 Selection',
		subtitles: [
			'Do not feel the need to make sure each color group is represented. Simply choose the 5 top tiles that resonate the most for you and rank them in order of importance.',
			"Click tiles in order of their rank. If you'd like to reset their order, simply click the selected tile.",
		],
		info: '',
	},
};
