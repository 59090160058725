import { httpClient } from '@/api/http';
import { Output, Question } from '../types';
import { slowBrainQuestions } from './mocks';

export const getSlowBrainQuestions = async (...tags: string[]): Promise<Question[]> => {
	const { data } = await httpClient.get<Question[]>('/flows/slow_brain/questions', {
		params: { tags: tags.join(',') },
	});

	return data;
};

export const getSlowBrainOutput = async (): Promise<Output> => {
	const { data } = await httpClient.get<Output>('/sessions/1/flow/slow_brain/output');
	return data;
};

export const mockGetSlowBrainQuestions = async (): Promise<Question[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(slowBrainQuestions), 500);
	});
