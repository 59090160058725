import { httpClient } from '@/api/http';
import { TileGroup, TileGroupSelected } from '../types';
import { fastBrainSelectedTiles, fastBrainTileGroups } from './mocks';

export const getFastBrainTileGroups = async (): Promise<TileGroup[]> => {
	const { data } = await httpClient.get<TileGroup[]>('/flows/fast_brain/tile-groups');
	return data;
};

export const mockGetFastBrainTileGroups = async (): Promise<TileGroup[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(fastBrainTileGroups), 500);
	});

export const getFastBrainTileGroupSelectedTiles = async (): Promise<TileGroupSelected[]> => {
	const { data } = await httpClient.get<TileGroupSelected[]>('/sessions/1/tile-groups', {
		params: { flow: 'fast_brain' },
	});
	return data;
};

export const mockGetFastBrainTileGroupSelectedTiles = async (): Promise<TileGroupSelected[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(fastBrainSelectedTiles), 500);
	});
