import { httpClient } from '../http';
import { TileSelectedPayload } from './types';

export const postSelectedTiles = async (
	tileGroupId: string,
	selectedTiles: TileSelectedPayload[]
): Promise<void> => {
	const { data } = await httpClient.post<void>(
		`/sessions/1/tile-groups/${tileGroupId}/tiles`,
		selectedTiles
	);
	return data;
};

export const mockPostSelectedTiles = async (
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_tileGroupId: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_selectedTiles: TileSelectedPayload[]
): Promise<void> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(), 500);
	});
