import { httpClient } from '@/api/http';
import { Output, Question, RiskLevel } from '../types';
import { portfolioRecommendationQuestions } from './mocks';

export const getPortfolioRecommendationQuestions = async (): Promise<Question[]> => {
	const { data } = await httpClient.get<Question[]>('/flows/recommendation/questions');
	return data;
};

export const getNextBestActionsOutput = async (): Promise<Output> => {
	const { data } = await httpClient.get<Output>('/sessions/1/flow/next_best_actions/output');
	return data;
};

export const getRiskToleranceLevel = async (): Promise<RiskLevel> => {
	const { data } = await httpClient.get<RiskLevel>(
		'/sessions/1/flows/risk_assessment/risk_tolerance_level'
	);
	return data;
};

export const mockGetPortfolioRecommendationQuestions = async (): Promise<Question[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(portfolioRecommendationQuestions), 500);
	});
