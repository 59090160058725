import { httpClient } from '@/api/http';
import { Answer } from '../types';
import { riskAssessmentAnswers } from './mocks';

export const getRiskAssessmentAnswers = async (): Promise<Answer[]> => {
	const { data } = await httpClient.get<Answer[]>('/sessions/1/flows/risk_assessment/answers');
	return data;
};

export const mockGetRiskAssessmentAnswers = async (): Promise<Answer[]> =>
	new Promise((resolve) => {
		setTimeout(() => resolve(riskAssessmentAnswers), 500);
	});
